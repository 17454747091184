import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";

import logo from "assets/logo/ic-logo.webp";
import logoWhite from "assets/logo/ic-logo-white.webp";

import UserIcon from "./NavbarComponents/UserIcon";

function NonAuth({ isPlayer }) {
  const [changeColor, setChangeColor] = useState(false);
  const [changeNavColor, setChangeNavColor] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const changeNavbarColor = () => {
      if (window.scrollY >= 2) {
        setChangeNavColor(true);
        setChangeColor(true);
      } else {
        setChangeNavColor(false);
        const noChangeColorIncludes = [
          "/email-verification",
          "/phone-verification",
          "/lessons",
        ];

        const shouldChangeColor = !noChangeColorIncludes.some((path) =>
          location.pathname.includes(path)
        );

        setChangeColor(shouldChangeColor);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);
    changeNavbarColor();
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [location.pathname]);

  const handleShowLogin = () => {
    setShowLogin((prev) => !prev);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: changeNavColor ? "white" : "transparent",
        top: "0px",
        zIndex: 1000,
        padding: "0px 10px",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="lg" sx={{ padding: "0px!important" }}>
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {isPlayer ? (
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                src={changeColor ? logo : logoWhite}
                alt="logo"
                width="150"
                height="40"
                className="text-white"
              />
            </Typography>
          ) : (
            <div>
              <img
                src={`${changeColor ? logo : logoWhite}`}
                alt="logo"
                width="150"
                height="40"
                className="text-white"
              />
            </div>
          )}

          {!isPlayer && (
            <div className="flex gap-1 items-center">
              <UserIcon
                changeColor={changeColor}
                changeNavColor={changeNavColor}
                showLogin={showLogin}
                handleShowLogin={handleShowLogin}
                setShowLogin={setShowLogin}
              />
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NonAuth;
