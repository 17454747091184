import * as masterclass from "./masterClass.api";
import * as user from "./user.api";
import * as course from "./course.api";
import * as auth from "./auth.api";
import * as payment from "./payment";
import * as affiliate from "./affiliate.api";

const apiModal = {
  masterclass,
  user,
  course,
  auth,
  payment,
  affiliate,
};

export default apiModal;
