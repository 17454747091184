import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './chatbot.css';
import chatimg from "../chatbot/bot.PNG"
import { FaPaperPlane } from "react-icons/fa";
import { IoExpand } from 'react-icons/io5';


function Chatbot({ setExpanded, expanded }) {
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('chatMessages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });


  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };


  const MAX_WORDS_LIMIT = 150;

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    const limitedInput = inputText.split(/\s+/).slice(0, MAX_WORDS_LIMIT).join(' ');
    setUserInput(limitedInput);
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();

  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();



    if (userInput.trim() === '') {
      return;
    }

    const newMessage = {
      role: 'user',
      content: userInput,
    };
    setMessages([...messages, newMessage]);
    setUserInput('');
    try {
      setLoading(true);
      const ipAddress = await axios.get('https://api.ipify.org?format=json')
      .then(response => response.data.ip)
      .catch(error => {
        return null;
      });
  
      const response = await axios.post('https://chatbot.interactivecares.xyz/chat/ask', {
        question: userInput,
        type: "3.5",
        ip:ipAddress

      });
      if (response) {
        setLoading(false);
        const assistantResponse = response.data.answer;
        const newAssistantMessage = {
          role: 'assistant',
          content: assistantResponse,
        };
        setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);
        localStorage.setItem('chatMessages', JSON.stringify([...messages, newMessage, newAssistantMessage]));
      }
    }
    catch (err) {
      setLoading(false);
    }
  };

  function parseMarkdown(markdown) {
    return markdown
      // Headers
      .replace(/^(#{1,6})\s*(.+)$/gm, (_, hashes, content) => {
        const level = hashes.length;
        return `<h${level}>${content}</h${level}>`;
      })
      // Bold and Italic
      .replace(/\*\*(.?)\*\*/g, '<strong>$1</strong>')
      .replace(/\*(.?)\*/g, '<em>$1</em>')
      // Ordered Lists
      .replace(/^\d+\.\s+(.+)$/gm, '<li>$1</li>')
      // Unordered Lists
      .replace(/^\*\s+(.+)$/gm, '<li>$1</li>')
      // Links
      .replace(/\[(.+?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer" style="color: #F39C12;">$1</a>')
      // Text Formatting
      .replace(/\*\*(.+?)\*\*/g, '<span style="color:#00FFFF;">$1</span>')
      // Images
      .replace(/!\[(.+?)\]\((.*?)\)/g, '<img alt="$1" src="$2" />')
      // Inline Code
      .replace(/`(.*?)`/g, '<code>$1</code>')
      // Blockquotes
      .replace(/^>\s*(.+)$/gm, '<blockquote>$1</blockquote>')
      // Horizontal Rule
      .replace(/^(-{3,}|\*{3,}|_{3,})$/gm, '<hr />')
      // Tables (simplified)
      .replace(/^\|(.+)\|$/gm, '<tr><td>$1</td></tr>')
      // Newlines
      .replace(/\n/g, '<br />');
  }



  useEffect(() => {
    const messageContainer = document.getElementById('message-container');

    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  }, [messages]);



  useEffect(() => {

    if (messages.length === 0) {
      const welcomeMessage = "Hello, I am the coolest chatbot in town. How can I help you today?";
      const newAssistantMessage = {
        role: 'assistant',
        content: welcomeMessage,
      };

      setMessages((prevMessages) => [...prevMessages, newAssistantMessage]);

      localStorage.setItem('chatMessages', JSON.stringify([newAssistantMessage]));
    }
  }, []);


  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === 'assistant') {
      const messageContainer = document.getElementById('message-container');
      if (messageContainer) {
        const spans = messageContainer.querySelectorAll('span');

        let index = 0;
        const interval = setInterval(() => {
          if (spans[index]) {
            spans[index].style.display = 'inline';
            index++;

            if (index === spans.length) {
              clearInterval(interval);
            }
          }
        }, 30);
      }
    }
  }, [messages]);


  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('chatMessages');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    let timeoutId;

    const setupTimeout = () => {
      timeoutId = setTimeout(() => {
        localStorage.removeItem('chatMessages');
      }, 10 * 60 * 1000);
    };

    const handleUserActivity = () => {
      clearTimeout(timeoutId);
      setupTimeout();
    };

    const setupBackgroundTimeout = () => {
      timeoutId = setTimeout(() => {
        localStorage.removeItem('chatMessages');
      }, 10 * 60 * 1000);
    };

    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);
    setupTimeout();
    setupBackgroundTimeout();
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('mousemove', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, []);



  return (
    <div className={` ${expanded ? 'expanded chatbot-container p-1 ' : 'chatbot-container p-1 rounded-[30px]'}`}>
      <nav className="navbar flex w-full justify-between">
        <h3 className='text-xl'>  Interactive Cares Chatbot</h3>
        <IoExpand className='cursor-pointer' onClick={toggleExpand} />
      </nav>
      <div id="message-container" className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} id="message-container" className={`message-container ${message.role} ${message.role === "assistant" && 'gap-1'}`}>
            <div className={`profile-picture-container ${message.role} ${message.role === "user" ? 'hidden' : 'w-[40px]'}`}>
              {message.role === 'assistant' && (
                <img
                  className="profile-picture"
                  src={chatimg}
                  alt="Chatbot Profile"
                />
              )}
            </div>
            <div className={`message ${message.role} ${message.role === "user" ? 'w-full' : 'w-[88%]'} `}>
              <div dangerouslySetInnerHTML={{ __html: parseMarkdown(message.content) }}></div>
            </div>
          </div>
        ))}
        {loading && <div className="message-container assistant">
          <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>}
      </div>
      <form onSubmit={handleSubmit}>
        <div className='flex w-full justify-center items-stretch'>
          <input
            ref={inputRef}
            type="text"
            className="chat-input w-11/12"
            placeholder="Type your message..."
            value={userInput}
            onChange={handleInputChange}
          />
          <button type="submit" disabled={loading} className="w-[70px] flex items-center justify-center h-auto bg-white rounded-full p-2 m-[6px] transition-transform transform hover:scale-105">
            <FaPaperPlane className='w-5 h-5' />
          </button>
        </div>
      </form>

    </div>
  );
}

export default Chatbot;
