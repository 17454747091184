import { useState } from "react";
import axios from "axios";
import Apiurl from "../Apiurl/Apiurl";
import useAuthToken from "./useAuthToken";

const useSendEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("access");
  const { authToken } = useAuthToken();

  const sendEvent = (event, label, payload) => {
    if (!authToken) {
      return;
    } else {
      setLoading(true);
      setError(null);
      axios
        .post(
          `${Apiurl.Baseurl}generics/create-analytics-track/`,
          { event, label, payload },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
          console.error("Error:", err);
        });
    }
  };

  return { sendEvent, loading, error };
};

export default useSendEvent;
