import React from "react";

import useProfile from "hooks/useProfile";
import userImage from "assets/UserImage.png";
import { AuthModal } from "components/Modals/AuthModal";
import useAuthModal from "hooks/useAuthModal";

import ProfileDrawer from "../Drawer/ProfileDrawer";

const UserIcon = ({ changeColor }) => {
  const { profile } = useProfile();

  const [open, setOpen] = React.useState(false);
  const { isModalOpen, openModal, closeModal } = useAuthModal();
  const toggleDrawer = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return profile ? (
    <div className="flex items-center gap-3">
      <div className="flex gap-2 items-center">
        {profile?.profile_picture ? (
          <img
            onClick={toggleDrawer}
            src={profile?.profile_picture}
            className="w-9 h-9 rounded-full object-cover cursor-pointer"
            alt=""
          />
        ) : (
          <img
            onClick={toggleDrawer}
            src={userImage}
            className="w-8 h-8 bg-white rounded-full cursor-pointer"
            alt=""
          />
        )}
      </div>

      <ProfileDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        profile={profile}
      />
    </div>
  ) : (
    <>
      <div>
        <button
          onClick={openModal}
          className={`${
            changeColor
              ? "bg-[#40405C] text-white"
              : "bg-[#cdcfd1] text-[#40405C]"
          } flex font-semibold px-6 py-[8px] rounded-full cursor-pointer text-sm md:text-[16px] lg:text-[16px]`}
        >
          <p>Login</p>
        </button>

        {isModalOpen && <AuthModal onClose={closeModal} />}
      </div>
    </>
  );
};

export default UserIcon;
