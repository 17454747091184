import Api from "./api";

const api = new Api();

export const login = async (params) => {
  return await api.post("/user/auth/login/", params);
};

export const register = async (params) => {
  return await api.post("/user/auth/register/", params);
};

export const loginGoogle = async (params) => {
  return await api.post("/user/auth/google-login/", { access_token: params });
};

export const profile = async () => {
  return await api.get("/user/auth/profile/");
};

export const emailConfirmResend = async () => {
  return await api.post("/user/auth/email-verify/");
};

export const emailVerify = async (data) => {
  return await api.put("/user/auth/email-verify/", data);
};

export const phoneVerify = async (data) => {
  return await api.post("/user/auth/phone-verify/", { phone_number: data });
};

export const otpVerify = async (data) => {
  return await api.put("/user/auth/phone-verify/", data);
};

export const reqResetPassword = async (data) => {
  return await api.post("/user/auth/reset-password/", data);
};

export const updatePassword = async (data) => {
  return await api.put("/user/auth/reset-password/", data);
};
