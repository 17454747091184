import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { BiBriefcase } from "react-icons/bi";
import { VscChecklist } from "react-icons/vsc";
import { TbAffiliate } from "react-icons/tb";
import { LuGraduationCap } from "react-icons/lu";
import { FaRegBookmark } from "react-icons/fa6";
import { AiOutlineProfile } from "react-icons/ai";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import userImage from "assets/UserImage.png";
import useAuthToken from "hooks/useAuthToken";

const ProfileDrawer = ({ profile, open, toggleDrawer }) => {
  const { setAuthToken } = useAuthToken();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = () => {
    queryClient.resetQueries(["profile"]);

    setAuthToken(undefined);
    //queryClient.clear();
    localStorage.clear();
    toggleDrawer();
    navigate("/");
  };

  return (
    <Drawer
      sx={{ zIndex: 4001 }}
      anchor="right"
      open={open}
      onClose={toggleDrawer}
    >
      <div className="flex flex-col justify-between h-screen p-4 w-[270px] md:w-[400px]">
        <Box role="presentation">
          <MdOutlineClose
            size={25}
            className="cursor-pointer"
            onClick={toggleDrawer}
          />
          <div className="flex justify-center flex-col items-center ">
            {profile?.profile_picture ? (
              <img
                src={profile?.profile_picture}
                className="h-[70px] w-[70px] rounded-full object-cover"
                alt=""
              />
            ) : (
              <img
                src={userImage}
                className="h-[70px] w-[70px] lg:bg-white rounded-full"
                alt=""
              />
            )}
            <div className="font-semibold text-[18px] capitalize mt-2 ">
              {profile?.first_name + " " + profile?.last_name}
            </div>

            <div className="text-[18px]  mt-2 max-w-full break-words overflow-hidden">
              {profile?.email}
            </div>
          </div>
          <Divider sx={{ borderStyle: "dotted", marginTop: 2 }} />

          <List>
            <Link to="/newDashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <RxDashboard size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Course Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/affiliate-dashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <TbAffiliate size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Affiliate Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/js-dashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <BiBriefcase size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Job Seeker" />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/hr-dashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <VscChecklist size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Recruiter Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/ir-dashboard">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <LuGraduationCap size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Instructor Dashboard" />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
          <Divider sx={{ borderStyle: "dotted", marginTop: 2 }} />

          <List>
            <Link to="/newDashboard/profile">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <AiOutlineProfile size={25} fill="black" />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link to="/newDashboard/wishlist">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <FaRegBookmark size={25} />
                  </ListItemIcon>
                  <ListItemText primary="Wishlist" />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>

        <div className="flex justify-center">
          <button
            onClick={handleLogout}
            className="bg-[rgb(233,208,208)] font-semibold  text-[18px]  py-3 px-16 rounded-md text-[rgb(131,51,51)] w-full"
          >
            Logout
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default ProfileDrawer;
