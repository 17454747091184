import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

import useProfile from "hooks/useProfile";
import useAuthToken from "hooks/useAuthToken";

import Footer from "../components/common/Footer/Footer";
import ResponsiveAppBar from "../components/common/Navbar/MuiNavbar";

const NavigationLayout = () => {
  const { profile } = useProfile();
  const { authToken } = useAuthToken();

  const location = useLocation();

  const dashboardRoutes = [
    "/newDashboard",
    "/affiliate",
    "/ir-",
    "/hr-",
    "/js-",
    "/lessons",
  ];

  const isDashboardRoute = dashboardRoutes.some((route) =>
    location.pathname.includes(route)
  );

  if (!profile || !authToken) {
    return (
      <React.Fragment>
        {<ResponsiveAppBar />}

        <Outlet />
        {<Footer />}
      </React.Fragment>
    );
  }

  const isEmailVerificationRoute = location.pathname === "/email-verification";
  const isPhoneVerificationRoute = location.pathname === "/phone-verification";

  if (!profile?.email_confirmed && !isEmailVerificationRoute) {
    return <Navigate to="/email-verification" replace />;
  }

  if (
    profile?.email_confirmed &&
    !profile?.phone_number &&
    !isPhoneVerificationRoute
  ) {
    return <Navigate to="/phone-verification" replace />;
  }

  return (
    <React.Fragment>
      {!isDashboardRoute && <ResponsiveAppBar />}

      <Outlet />
      {!isDashboardRoute && <Footer />}
    </React.Fragment>
  );
};

export default NavigationLayout;
