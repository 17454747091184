import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FaBorderAll } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";
import { BsInfoSquare } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { GoBriefcase } from "react-icons/go";
import { HiRectangleGroup } from "react-icons/hi2";
import { TbAffiliate } from "react-icons/tb";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { SiElectronbuilder } from "react-icons/si";
import { SiMicrodotblog } from "react-icons/si";

import logo from "assets/logo/ic-logo.webp";

export default function ResponsiveDrawer({ opendrawer, onClose }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const list = (anchor) => (
    <div className="w-[250px] h-full relative" onKeyDown={onClose}>
      <List>
        <div className="navBarAccordion flex flex-col w-full p-5">
          <div className="flex justify-center pb-5">
            <img src={logo} className="w-20 h-auto" alt="" />
          </div>

          <div className="flex flex-col items-start gap-3">
            <Link to="/" className="flex gap-2 items-center" onClick={onClose}>
              <IoHomeOutline />
              <h3 className="text-[18px] ">Home</h3>
            </Link>

            <Link
              to="/allcourses"
              className="flex gap-2 items-center"
              onClick={onClose}
            >
              <FaBorderAll />
              <h3 className="text-[18px] ">All Courses</h3>
            </Link>

            <Link
              to="/business"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdBusiness />
              Business
            </Link>

            <Link
              to="/allCareerPath"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdOutlineBusinessCenter />
              Career Path
            </Link>

            <Link
              to="/about"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <BsInfoSquare />
              About Us
            </Link>

            <Divider
              sx={{ borderStyle: "dotted", marginTop: 2, width: "100%" }}
            />

            <div className="text-[#6a6f73] font-semibold">
              More from interactive
            </div>

            <Link
              to="/career"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <GoBriefcase />
              Career
            </Link>

            <Link
              to="/hiringpage"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <HiRectangleGroup />
              Hire
            </Link>

            <Link
              to="/affiliation"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <TbAffiliate />
              Affiliate
            </Link>

            <Link
              to="/support-session"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <MdOutlineBrowserNotSupported />
              Support Session
            </Link>

            <Link
              to="/js-dashboard/resume"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <SiElectronbuilder />
              Resume Builder
            </Link>

            <Link
              to="/blogs"
              className="flex gap-2 items-center text-[18px] "
              onClick={onClose}
            >
              <SiMicrodotblog />
              Blogs
            </Link>

            {/* <div className="my-1  p-2 rounded-lg ml-[-20px]">
              <Accordion sx={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  expandIcon={
                    <BsArrowsAngleExpand style={{ marginLeft: "5px" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      listStyle: "underline",
                      paddingLeft: "12px",
                    }}
                  >
                    More
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col">
                    <ul>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link to="/career" className=" text-[18px] underline">
                          Career
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/hiringpage"
                          className=" text-[18px] underline"
                        >
                          Hire
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/affiliation"
                          className=" text-[18px] underline"
                        >
                          Affiliate
                        </Link>
                      </li>

                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/support-session"
                          className=" text-[18px] underline"
                        >
                          Support Session
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link
                          to="/js-dashboard/resume"
                          className=" text-[18px] underline"
                        >
                          Resume Builder
                        </Link>
                      </li>
                      <li className="my-2 ml-2">
                        {" "}
                        <Link to="/blogs" className=" text-[18px] underline">
                          Blogs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </div>
        </div>
      </List>
    </div>
  );

  return (
    <div className=" ">
      <Drawer
        anchor="left"
        open={opendrawer}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: "#ffffff",
          },
        }}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
