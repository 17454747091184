import create from "zustand";
import { persist } from "zustand/middleware";

const initialState = {
  authToken: undefined,
};

const useAuthToken = create(
  persist(
    (set) => ({
      ...initialState,
      setAuthToken: (token) => {
        set({
          authToken: token,
        });
      },
      removeAuthToken: () => {
        set(initialState);
      },
    }),
    { name: "auth" }
  )
);

export default useAuthToken;
