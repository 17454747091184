import Api from "./api";

const api = new Api();

export const affiliateLinkedClicked = async (params) => {
  return await api.post(`/affiliate/affiliate-links-clicked/`, params);
};

export const getAffiliate = async (id) => {
  return await api.get(`/affiliate/affiliate-exists/${id}/`);
};

export const createAffiliateProfile = async () => {
  return await api.post("/affiliate/api/v2/affiliate/");
};

export const payoutRequest = async (params) => {
  return await api.post("/affiliate/affiliate_payout_request/", params);
};
