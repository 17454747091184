import Api from "./api";

const api = new Api();

export const recent = async () => {
  return await api.get("/course/master-class/");
};

export const upcoming = async () => {
  return await api.get("/marketing/upcoming-masterclass/");
};

export const findById = async (id) => {
  return await api.get(`/course/masterclass-details/${id}/`);
};

export const register = async (data) => {
  return await api.post(`/course/masterclass-registration/`, data);
};
