import Api from "./api";

const api = new Api();

export const getUser = async (id) => {
  return await api.get(`log_reg/users/${id}/`);
};

export const getWhishList = async () => {
  return await api.get(`/user/wishlist/`);
};

export const addWishList = async (id) => {
  return await api.post(`/user/wishlist/${id}/`);
};

export const deleteWishList = async (id) => {
  return await api.delete(`/user/wishlist/${id}/`);
};

export const getFreeEnroll = async (data) => {
  return await api.post(`marketing/free-enrollment/`, data);
};

export const getJobSeeker = async (id) => {
  return await api.get(`/user/jobseeker-exists/${id}/`);
};

export const getInstructor = async (id) => {
  return await api.get(`/user/instructor-exists/${id}/`);
};

export const getRecruiter = async (id) => {
  return await api.get(`/user/recruiter-exists/${id}/`);
};

export const createInstructorProfile = async (params) => {
  return await api.post("/user/api/v2/instructor/", params);
};

export const createRecruiterProfile = async (params) => {
  return await api.post("/user/api/v2/recruiter/", params);
};

export const createJobSeekerProfile = async () => {
  return await api.post("/user/api/v2/job_seeker/");
};
