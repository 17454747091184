import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { BiMenuAltLeft } from "react-icons/bi";
import Container from "@mui/material/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import ReactGA from "react-ga";

import logo from "assets/logo/ic-logo.webp";
import logoWhite from "assets/logo/ic-logo-white.webp";

import UserIcon from "./NavbarComponents/UserIcon";
import SearchBar from "./NavbarComponents/SearchBar";
import { searchQueryState } from "../../../state/SearchQueryState";
import { searchParamState } from "../../../state/SearchParamState";
import ResponsiveDrawer from "./NavbarComponents/ResponsiveDrawer";
import LeftSideNav from "./NavbarComponents/LeftSideNav";
import useSendEvent from "hooks/useSendEvent";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [changeColor, setChangeColor] = useState(false);
  const [changeNavColor, setChangeNavColor] = useState(false);

  const { sendEvent } = useSendEvent();

  const handleSendEvent = () => {
    sendEvent("Interactivecares Logo", "home");
  };

  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParam, setSearchParam] = useRecoilState(searchParamState);
  // const searchParam = new URLSearchParams(location.search).get("search");

  const handleDeleteSearch = () => {
    if (searchParam !== null) {
      const newSearchParam = new URLSearchParams(location.search);
      newSearchParam.delete("search");
      setSearchQuery("");
      navigate({
        pathname: "/allcourses",
        search: newSearchParam.toString(),
      });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    if (searchQuery) {
      sendEvent(`search=${searchQuery}`, "home");
      ReactGA.event({
        category: "Search",
        action: "User searched",
        label: searchQuery,
      });
    }
    if (searchQuery !== null && searchQuery !== "") {
      navigate(`/allcourses?search=${encodeURIComponent(searchQuery)}`, {
        state: {
          searchQuery,
        },
      });
    } else {
      sendEvent("search", "home");
      navigate(`/allcourses`, {
        state: {
          searchQuery,
        },
      });
    }
  };

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const changeNavbarColor = () => {
      if (window.scrollY >= 2) {
        setChangeNavColor(true);
        setChangeColor(true);
      } else {
        setChangeNavColor(false);
        const noChangeColorPaths = [
          "/hiringpage",
          "/business",
          "/join-group",
          "/mern-resource",
          "/jobseeker",
          "/about",
          "/allCareerPath",
          "/register-form",
          "/support-session",
          "/phone-verification",
          "/",
          "/login",
          "/hmregistration",
          "/hmlogin",
          "/privacy-policy",
          "/irlogin",
          "/irregister",
          "/email-verification",
          "/login",
          "/lost-password",
          "/register",
          "/affiliation",
          "/allcourses",
          "/globallogin",
          "/career",
          "/terms-and-conditions",
          "/report-issue",
        ];

        const noChangeColorIncludes = [
          "career-paths",
          "/payment",
          "/log_reg/reset-password",
          "/log_reg/activate",
          "/jobs",
          "/register-form",
          "/courseDetails",
          "/reset-password",
          "/masterclass-details",
          "/masterclass-recorded",
          "/lessons",
          "/quiz",
          "/blogs",
        ];

        const shouldChangeColor =
          !noChangeColorPaths.includes(location.pathname) &&
          !noChangeColorIncludes.some((path) =>
            location.pathname.includes(path)
          );

        setChangeColor(shouldChangeColor);
      }
    };

    window.addEventListener("scroll", changeNavbarColor);
    changeNavbarColor();
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, [location.pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: changeNavColor ? "white" : "transparent",
        top: "0px",
        zIndex: 1000,
        padding: "0px 10px",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="lg" sx={{ padding: "0px!important" }}>
        <Toolbar
          disableGutters
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            variant="h6"
            noWrap
            component={Link}
            onClick={handleSendEvent}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src={changeColor ? logo : logoWhite}
              alt="logo"
              width="150"
              height="40"
              className="text-white"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleToggleDrawer}
            >
              <BiMenuAltLeft
                style={{
                  color: changeNavColor || changeColor ? "black" : "white",
                }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <div className="p-5 flex flex-col">
                <ResponsiveDrawer
                  opendrawer={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                />
              </div>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <LeftSideNav changeColor={changeColor} />
          </Box>

          <div className="flex gap-4 items-center">
            <div className="flex-auto">
              <SearchBar
                handleSearchInputChange={handleSearchInputChange}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                changeColor={changeColor}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                handleDeleteSearch={handleDeleteSearch}
                suggestions={[
                  "Data Analytics",
                  "DevOps",
                  "Digital Marketing",
                  "MERN",
                  "Flutter",
                ]}
              />
            </div>
            <div className="flex-none">
              <UserIcon changeColor={changeColor} />
            </div>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
