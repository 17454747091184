import React from "react";
import { useQuery, useQueryClient } from "react-query";

import api from "api";
import useAuthToken from "./useAuthToken";

const useProfile = (params) => {
  const queryClient = useQueryClient();
  const { authToken } = useAuthToken();

  const { data, isLoading, error, refetch, isRefetching, ...rest } = useQuery({
    queryKey: ["profile"],
    queryFn: () => api.auth.profile(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!authToken || !!localStorage.getItem("access"),
    ...params,
  });

  const setProfile = React.useCallback(
    (newProfile) => queryClient.setQueryData(["profile"], newProfile),
    [queryClient]
  );

  return {
    profile: data,
    isLoading,
    refetch,
    error,
    isRefetching,
    setProfile,
    ...rest,
  };
};

export default useProfile;
