const capitalize = (str) =>
  typeof str === "string" ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export class ApiError extends Error {
  constructor(error, status = 500) {
    let message = ApiError.formatError(error);

    super(message);
    this.name = "ApiError";
    this.status = status;
    this.errorsObject = error;
  }

  static formatError(error) {
    if (typeof error === "string") {
      return capitalize(error);
    }

    if (Array.isArray(error)) {
      return error
        .map((e) => (typeof e === "string" ? capitalize(e) : ""))
        .filter(Boolean)
        .join(". ");
    }

    if (typeof error === "object" && error !== null) {
      return Object.values(error)
        .flatMap((value) => {
          if (Array.isArray(value)) {
            return value
              .map((e) => (typeof e === "string" ? capitalize(e) : ""))
              .filter(Boolean)
              .join("; ");
          }

          return typeof value === "string" ? capitalize(value) : "";
        })
        .filter(Boolean)
        .join(". ");
    }

    return "Unknown error occurred";
  }

  toString() {
    return this.message;
  }
}
