import axios from "axios";
import { BASE_URL } from "config/env";
import { ApiError } from "utils/ApiError";

import useAuthToken from "hooks/useAuthToken";
export default class Api {
  #instance;
  constructor(defaultConfig) {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      responseType: "json",
      ...defaultConfig,
    });

    instance.interceptors.request.use(
      function (config) {
        const token = localStorage.getItem("access");
        const { authToken } = useAuthToken.getState();
        if (authToken) {
          config.headers["Authorization"] = `Bearer ${authToken}`;
        } else if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.status === 401) {
          const { setAuthToken } = useAuthToken.getState();
          setAuthToken(undefined);
          localStorage.clear();
        }

        if (!error.response) {
          throw new Error("Unhandled error happened...");
        }

        const errorData = error.response?.data;
        throw new ApiError(errorData);
      }
    );

    this.#instance = instance;
  }

  async #request(config) {
    const response = await this.#instance.request(config);
    return response.data;
  }

  async get(url, params, config) {
    return this.#request({ url, mehtod: "get", params, ...config });
  }

  async post(url, data, config) {
    return this.#request({ url, method: "post", data, ...config });
  }

  async put(url, data, config) {
    return this.#request({ url, method: "put", data, ...config });
  }

  async delete(url, params, config) {
    return this.#request({ url, method: "delete", params, ...config });
  }
}
