import React from "react";
import { Navigate } from "react-router-dom";

const AuthLayout = ({ children }) => {
  const token = localStorage.getItem("access");

  if (token) {
    return <Navigate to="/newDashboard" />;
  } else {
    return <div>{children}</div>;
  }
};

export default AuthLayout;
