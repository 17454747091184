import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdTrendingUp } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import useSendEvent from "hooks/useSendEvent";

const SearchBar = ({
  handleSearchInputChange,
  searchQuery,
  setSearchQuery,
  setSearchParam,
  changeColor,
  handleDeleteSearch,
  suggestions = [],
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const { sendEvent } = useSendEvent();

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    sendEvent(`search=${suggestion}`, "navbar");
    setSearchQuery(suggestion);
    setSearchParam(suggestion);
    navigate(`/allcourses?search=${encodeURIComponent(suggestion)}`, {
      state: { searchQuery: suggestion },
    });
    setShowSuggestions(false);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <form
      onSubmit={handleSearchInputChange}
      ref={searchRef}
      className="relative mx-2 w-full max-w-[640px]"
    >
      <div
        className={`relative flex items-center rounded-full shadow-sm transition-all duration-300 focus-within:shadow-lg ${
          changeColor
            ? "bg-[#DEDEDE] border-[#DEDEDD]"
            : "bg-[#40405c] border-[#2E2D4D]"
        }`}
      >
        <input
          type="text"
          className={`w-full py-2 pl-4 pr-3 text-sm rounded-full outline-none bg-transparent ${
            changeColor
              ? "text-black placeholder-gray-500"
              : "text-white placeholder-gray-400"
          }`}
          placeholder="Search"
          value={searchQuery}
          onChange={handleInputChange}
          onFocus={() => setShowSuggestions(true)}
        />

        {searchQuery !== "" && (
          <button
            type="button"
            className="p-2 hover:opacity-75"
            onClick={() => {
              handleDeleteSearch();
              setShowSuggestions(false);
            }}
          >
            <RxCross2
              className={`h-5 w-5 ${
                changeColor ? "text-gray-600" : "text-gray-400"
              }`}
            />
          </button>
        )}

        <button
          type="submit"
          className={`px-4 py-[9px] rounded-r-full border-l transition-colors duration-200 ${
            changeColor
              ? "bg-[#4d4d5a]  border-[#40405C]"
              : "bg-[#67679983]  border-[#40405c]"
          }`}
        >
          <IoSearch
            className={`h-5 w-5 ${changeColor ? "text-white" : "text-white"}`}
          />
        </button>
      </div>

      {showSuggestions && (
        <ul
          className={`absolute top-full mt-2 w-full rounded-xl overflow-hidden z-10 shadow-lg ${
            changeColor ? "bg-white" : "bg-[#40405c]"
          }`}
        >
          {suggestions
            .filter((suggestion) =>
              suggestion.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((suggestion, index) => (
              <React.Fragment key={index}>
                <li
                  onClick={() => handleSuggestionClick(suggestion)}
                  className={`cursor-pointer px-4 py-2 transition-colors duration-200 ${
                    changeColor ? "hover:bg-gray-100" : "hover:bg-[#48486d]"
                  }`}
                >
                  <div className="flex items-center gap-4">
                    <IoMdTrendingUp
                      className={`${
                        changeColor ? "text-gray-600" : "text-gray-400"
                      }`}
                      size={20}
                    />
                    <p
                      className={`${
                        changeColor ? "text-gray-900" : "text-white"
                      }`}
                    >
                      {suggestion}
                    </p>
                  </div>
                </li>
                {index < suggestions.length - 1 && (
                  <div
                    className={`border-t ${
                      changeColor ? "border-gray-200" : "border-gray-700"
                    }`}
                  />
                )}
              </React.Fragment>
            ))}
        </ul>
      )}
    </form>
  );
};

export default SearchBar;
